import {
  DrawerProps,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  ModalHeaderProps,
  ModalFooterProps,
  DrawerFooter,
  DrawerBody,
} from "@chakra-ui/react";
import { CSSProperties } from "@emotion/serialize";
import { customEvent, EventProps, VStack } from "goi_common";
import React, { ReactElement, ReactNode } from "react";
import { FooterItemProps, HeaderItemProps } from "goi_common";
import { OverlayDrawerFooterTwoBtnType } from "./OverlayDrawerFooter";
import { OverlayDrawerHeaderTitleType } from "./OverlayDrawerHeader";

export interface OverlayDrawerFooterProps {
  item: FooterItemProps[];
}

export interface OverlayDrawerHeaderProps {
  item: HeaderItemProps;
}

export interface OverlayDrawerProps {
  body: ReactElement;
  headerProps: OverlayDrawerHeaderProps;
  footerProps: OverlayDrawerFooterProps;
}

export default function FullscreenDrawer({
  isOpen,
  onClose,
  closeEvent,
  drawerWrapProps,
  drawerContentStyle,
  closeOnOverlayClick,
  header,
  body,
  footer,
}: {
  isOpen: boolean;
  onClose: () => void;
  closeEvent?: EventProps;
  closeOnOverlayClick?: boolean;
  drawerWrapProps?: Omit<DrawerProps, "children" | "isOpen" | "onClose">;
  drawerContentStyle?: CSSProperties;
  header?: { content: ReactElement; style?: ModalHeaderProps };
  body: ReactNode;
  footer?: { content: ReactElement; style?: ModalFooterProps };
}) {
  return (
    <Drawer
      isFullHeight
      {...drawerWrapProps}
      isOpen={isOpen}
      closeOnOverlayClick={closeOnOverlayClick}
      onClose={() => {
        if (closeEvent) customEvent(closeEvent);
        onClose();
      }}
      placement="bottom"
      autoFocus={false}
    >
      <DrawerOverlay color="rgba(0, 0, 0, 0.7)" />
      <DrawerContent
        maxHeight="100%"
        margin="auto"
        width="90% !important"
        alignItems="center"
        backgroundColor="transparent"
      >
        <VStack
          width="100%"
          css={{
            ...drawerContentStyle,
            background: drawerContentStyle?.background || "white",
            borderRadius: drawerContentStyle?.borderRadius || "10px",
          }}
        >
          {header && (
            <DrawerHeader {...header.style} padding={header.style?.padding || "0px"} width="100%">
              {header.content}
            </DrawerHeader>
          )}
          <DrawerBody padding="0px">{body}</DrawerBody>
          {footer && (
            <DrawerFooter
              {...footer.style}
              width="100%"
              borderRadius={footer.style?.borderRadius || "0px 0px 5px 5px"}
              overflow="hidden"
              padding={footer.style?.padding || 0}
            >
              {footer.content}
            </DrawerFooter>
          )}
        </VStack>
      </DrawerContent>
    </Drawer>
  );
}

FullscreenDrawer.Footer = {
  twoBtnType: OverlayDrawerFooterTwoBtnType,
};
FullscreenDrawer.Header = {
  titleType: OverlayDrawerHeaderTitleType,
};
