import { useDialog } from "@/hooks/useDialog";
import { Axios } from "@/utils/axios";
import { Drawer, DrawerContent, DrawerOverlay } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { colors, customEvent, HStack, VStack } from "goi_common";

export default function DormantDrawer({
  drawerOpen,
  onDrawerClose,
}: {
  drawerOpen: boolean;
  onDrawerClose: () => void;
}) {
  const { onOpen, onClose } = useDialog();
  return (
    <Drawer
      isOpen={drawerOpen}
      onClose={() => {
        onDrawerClose();
      }}
      placement="bottom"
      closeOnOverlayClick={false}
    >
      <DrawerOverlay color="rgba(0, 0, 0, 0.7)" />
      <DrawerContent
        css={css`
          border-radius: 10px 10px 0px 0px;
          background-color: transparent;
          width: 100% !important;
          align-items: center;
          max-height: 450px;
          overflow-y: auto;
        `}
      >
        <VStack
          gap={1}
          maxWidth="360px"
          width="100%"
          background={colors.white}
          borderRadius="10px 10px 0px 0px"
        >
          <VStack gap={10} padding="32px 0px 0px" alignItems="center">
            <div className="subtitle2_b">고객님은 현재 휴면 상태입니다.</div>
            <div className="subtitle3 gray500" css={{ textAlign: "center" }}>
              하단의 [휴면 해제] 버튼을 통해
              <br />
              <span className="orange650"> 휴면 상태를 해제해주세요.</span>
            </div>
            <VStack
              width="100%"
              background={colors.orange50}
              justifyContent="center"
              alignItems="center"
              padding="43px 24px"
              gap={24}
            >
              <div className="caption1">
                고객님의 개인정보 보호를 위해 ‘개인정보보호법 제 39조 6에 의거 1년 동안 서비스를
                이용하지 않을 시 개인정보는 분리보관됩니다.
              </div>
              <table
                className="caption1"
                css={{
                  width: "100%",
                  border: `1px solid ${colors.gray300}`,
                  ["th, td"]: {
                    border: `1px solid ${colors.gray300}`,
                    backgroundColor: "white",
                    padding: "11px 0px",
                    textAlign: "center",
                  },
                }}
              >
                <th css={{ width: "30%" }}>처리방식</th>
                <th>개인정보 항목</th>
                <tr>
                  <td>분리보관</td>
                  <td>이름, 휴대폰번호, 이메일, 성별, 출생연도</td>
                </tr>
              </table>
            </VStack>
          </VStack>
          <HStack
            gap={12}
            css={css`
              padding: 16px 24px 30px;
              position: sticky;
              bottom: 0;
            `}
          >
            <button
              className="button black"
              css={css`
                width: 80px;
                background-color: ${colors.gray100};
                border-radius: 5px;
                padding: 10px 0;
              `}
              onClick={() => {
                customEvent({
                  newGtm: {
                    path: "login",
                    trigger_and_target: "drawer_dormant_close-close_drawer",
                  },
                });
                onDrawerClose();
              }}
            >
              닫기
            </button>
            <button
              className="button white"
              css={css`
                background-color: ${colors.black};
                border-radius: 5px;
                flex: 1;
                padding: 10px 0;
              `}
              onClick={() => {
                customEvent({
                  newGtm: {
                    path: "login",
                    trigger_and_target: "drawer_dormant_revert-revert_dormancy",
                  },
                });
                Axios.post("/users/revert_dormancy/")
                  .then((res) => {
                    if (res.status === 204) {
                      onDrawerClose();
                      onOpen({
                        type: 1,
                        header: "다시 오신 것을 환영해요",
                        children: (
                          <>
                            휴면이 해제되었으니
                            <br />
                            다시 로그인해주세요
                          </>
                        ),
                        footerProps: {
                          text: "확인",
                          onClick: () => {
                            customEvent({
                              newGtm: {
                                path: "login",
                                trigger_and_target: "modal_confirm-close_modal",
                              },
                            });
                            onClose();
                          },
                        },
                      });
                    }
                  })
                  .catch((err) => {
                    alert("잘못된 접근 입니다. 다시 로그인 해주세요.");
                    onDrawerClose();
                  });
              }}
            >
              휴면 해제하기
            </button>
          </HStack>
        </VStack>
      </DrawerContent>
    </Drawer>
  );
}
