import FileRequestModal from "@/containers/Parlor/ParlorDetailContainer/components/Modal/FileRequestModal";
import { Box, Flex } from "@chakra-ui/react";
import { useOverlay } from "@toss/use-overlay";
import { colors, customEvent, getCdnImageSrc, HStack, Image } from "goi_common";

export default function FullscreenDrawerContent({
  onClose,
  onSuccess,
}: {
  onClose: any;
  onSuccess: any;
}) {
  const overlay = useOverlay();
  const requestEstimate = () => {
    customEvent({
      newGtm: {
        path: "gnb",
        trigger_and_target: "open_file_request_modal",
      },
    });
    overlay.open(({ isOpen, close: onFileRequestClose }) => {
      return (
        <FileRequestModal
          path="login"
          isOpen={isOpen}
          onClose={() => {
            onFileRequestClose();
            onSuccess();
          }}
        />
      );
    });
  };

  return (
    <Box
      gap={1}
      width="100%"
      height="100%"
      backgroundColor={colors.gray900}
      borderRadius="10px"
      position="relative"
      overflow="hidden"
    >
      <Image src={getCdnImageSrc("platform/login/request-background.png")} />
      <HStack
        position="absolute"
        style={{
          bottom: 0,
          zIndex: 2,
        }}
        borderRadius="10px"
        width="100%"
        height="60px"
      >
        <Flex
          onClick={onClose}
          borderRadius="0 0 0 10px"
          width="30%"
          className="button_16_sb"
          backgroundColor={colors.black}
          color={colors.white}
          alignItems="center"
          justifyContent="center"
        >
          닫기
        </Flex>
        <Flex
          onClick={requestEstimate}
          borderRadius="0 0 10px 0"
          width="70%"
          className="button_16_sb"
          backgroundColor={colors.orange650}
          color={colors.white}
          alignItems="center"
          justifyContent="center"
        >
          자료 받아보기
        </Flex>
      </HStack>
    </Box>
  );
}
