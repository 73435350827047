import styled from "@emotion/styled";
import { flexCentering, Button, Icon, EventProps } from "goi_common";
import { ReactNode } from "react";

function KakaoButton({
  onClick,
  event,
  kakaoButtonLoginText,
}: {
  onClick: () => void;
  event: EventProps;
  kakaoButtonLoginText?: ReactNode;
}) {
  return (
    <KakaoButtonRoot onClick={onClick} event={event}>
      <Icon src="/kakao.svg" alt="check" width={17} height={16} />
      <div css={{ marginRight: "9px" }} />
      <div css={{ maxWidth: "380px" }}>
        {kakaoButtonLoginText || "장례 비용, 절차, 맞춤형 할인까지 한번에 알아보기"}
      </div>
    </KakaoButtonRoot>
  );
}

const KakaoButtonRoot = styled(Button)`
  ${flexCentering("row")}
  background-color: var(--kakao);
  width: 100%;
  max-width: 412px;
  min-width: 312px;
  margin: auto;
  height: 56px;
  color: var(--kakaoText);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  border-radius: 5px;
`;

export default KakaoButton;
