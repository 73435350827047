import { css } from "@emotion/react";
import { colors, customEvent, HStack } from "goi_common";
import React from "react";
import { OverlayDrawerFooterProps } from ".";
import { FooterItemProps } from "goi_common";

const footerOnClick = (prop: FooterItemProps) => {
  if (prop.event) customEvent(prop.event);
  prop.onClick();
};

export function OverlayDrawerFooterTwoBtnType({ props }: { props: OverlayDrawerFooterProps }) {
  const { item } = props;

  return (
    <HStack gap={12} padding="16px 24px 30px" width="100%">
      <button
        className="button black"
        css={css`
          width: ${item[0].width};
          background-color: ${colors.gray100};
          border-radius: 5px;
          padding: 10px 0;
        `}
        onClick={() => {
          footerOnClick(item[0]);
        }}
      >
        {item[0].text}
      </button>
      <button
        className="button white"
        css={css`
          background-color: ${colors.black};
          border-radius: 5px;
          flex: 1;
          padding: 10px 0;
        `}
        onClick={() => {
          footerOnClick(item[1]);
        }}
      >
        {item[1].text}
      </button>
    </HStack>
  );
}
