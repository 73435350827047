import { isLoggedInState } from "@/atoms/accountState";
// import { estimateKeyState } from "@/atoms/estimateState";
import Dialog from "@/components/Dialog/Dialog";
import { Axios } from "@/utils/axios";
import KakaoButton from "./components/KakaoButton";
import parlors from "@/constants/parlors_detail.json";
import * as ChannelService from "@channel.io/channel-web-sdk-loader";

import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";

import {
  ChatbotModalContent,
  Checkbox,
  ColorIcon,
  colors,
  cooKeys,
  customEvent,
  Field,
  getCookie,
  getUserProperties,
  HackleEventName,
  HLine,
  HStack,
  LargeButton,
  LARGEL_BUTTON_TYPE,
  MQ,
  OverlayModal,
  pixelCustomEvent,
  PixelEventName,
  removeCookie,
  setCookie,
  Spacing,
  useResponsive,
  VStack,
} from "goi_common";

import { Box, useDisclosure } from "@chakra-ui/react";
// import { useCompareEstimateResult } from "containers/EstimateCompareContainer/remotes/hooks/useCompareEstimateResult";

import FullscreenDrawerContent from "@/components/common/Drawer/FullscreenDrawerContent";
import FullscreenDrawer from "@/components/common/Drawer/OverlayDrawer/FullscreenDrawer";
import { HackleClient } from "@/utils/hackle";
import { css } from "@emotion/react";
import { useOverlay } from "@toss/use-overlay";
import DormantDrawer from "./components/DormantDrawer";
import { 장례준비긴급도_LIST_EVENT_KEY } from "../MatchContainer/constants/request";

export const LoginContainer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isOpen: isOpenNoKakao, onToggle: onToggleOpenNoKakao } = useDisclosure();
  const router = useRouter();
  // const track = useTrack();

  // const key = useRecoilValue(estimateKeyState);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const { isBiggerThanLg } = useResponsive();
  const [핸드폰번호, set핸드폰번호] = useState("");
  // const { postEstimate } = useCompareEstimateResult();
  const 핸드폰번호체크용 = useMemo(() => {
    const 번호만 = 핸드폰번호.replaceAll("-", "");
    if (번호만.length == 10) {
      return {
        value: `${번호만.substring(0, 3)}-${번호만.substring(3, 6)}-${번호만.substring(6, 10)}`,
        isValid: true,
      };
    } else if (번호만.length === 11) {
      return {
        value: `${번호만.substring(0, 3)}-${번호만.substring(3, 7)}-${번호만.substring(7, 11)}`,
        isValid: true,
      };
    } else {
      return {
        isValid: false,
      };
    }
  }, [핸드폰번호]);
  const [동의s, set동의s] = useState([false, false, false, false]);

  const toggle동의s = (index: number) => {
    const value = 동의s[index];
    if (index === 0) {
      if (value) {
        set동의s([false, false, false, false]);
      } else {
        set동의s([true, true, true, true]);
      }
    } else {
      const new동의s = [...동의s];
      new동의s[index] = !value;
      if (!new동의s[1] && !new동의s[2] && !new동의s[3]) {
        set동의s([false, false, false, false]);
      } else if (new동의s[1] && new동의s[2] && new동의s[3]) {
        set동의s([true, true, true, true]);
      } else {
        new동의s[0] = false;
        set동의s(new동의s);
      }
    }
  };

  const [isKakaoAutoLogin, setIsKakaoAutoLogin] = useState<boolean>();
  const {
    isOpen: isOpenDormantDrawer,
    onOpen: openDormantDrawer,
    onClose: closeDormantDrawer,
  } = useDisclosure();

  const {
    isOpen: isOpenAlreadySignedUp,
    onOpen: openAlreadySignedUp,
    onClose: closeAlreadySignedUp,
  } = useDisclosure();

  useEffect(() => {
    ChannelService.hideChannelButton();
    if (!process.browser) return;

    const flag = getCookie(cooKeys.kakao_auto_login_handled);
    const isKakaoWebView = navigator.userAgent.includes("KAKAOTALK");
    const kakaoSuccessCode = router.query.code;

    if (flag && kakaoSuccessCode && isKakaoWebView) {
      setIsKakaoAutoLogin(true);
      return;
    }

    if (!flag && isKakaoWebView) {
      setIsKakaoAutoLogin(true);
      if (!flag && !kakaoSuccessCode && isKakaoWebView && !isLoggedIn) {
        handleKakaoAuth();
        setCookie(cooKeys.kakao_auto_login_handled, "YES");
      }
    }
  }, [router.query]);

  const to = getCookie(cooKeys.kakao_redirect);
  const previous = getCookie(cooKeys.previous);
  const quotationRequest = getCookie(cooKeys.quotation_request);
  const facilityQuotationRequestId = getCookie(cooKeys.quotation_request_facility);
  const kakaoButtonLoginText = useMemo(() => {
    try {
      if (previous && previous.length > 0) {
        const parlorMatchRequest = previous.find((item: string) =>
          item.includes("match/parlor-request"),
        );
        if (parlorMatchRequest) {
          if (parlorMatchRequest.includes("parlor-requests")) {
            const parlorId = parlorMatchRequest.split("/")[3].split("?")[0];
            if (parlorId) {
              const parlor = parlors.find((p) => p.id === Number(parlorId));
              if (parlor) {
                return (
                  <>
                    {parlor.companyname}
                    <br /> 장례 총 비용 알아보기
                  </>
                );
              }
            }
          } else {
            const parlorName = decodeURI(parlorMatchRequest.split("/")[3].split("?")[0]);

            if (parlorName) {
              return (
                <>
                  {parlorName}
                  <br />
                  장례 총 비용 알아보기
                </>
              );
            }
          }
        }
      }
      return;
    } catch (err) {
      return;
    }
  }, [previous]);

  const getFromToMemo = () => {
    return `From: ${
      !previous || previous.length === 0 ? router.asPath : previous.map((p: string) => decodeURI(p))
    }, To: ${decodeURI(to)} \n 주요 방문 기능(최근부터): ${getCookie(cooKeys.visited_feature)}`;
  };

  const onLoginSuccess = async (res: any) => {
    if (res.status == 200 || res.status == 201) {
      const action = getCookie(cooKeys.goi_action);
      const event_from = getCookie(cooKeys.event_from);

      setIsLoggedIn(true);
      const {
        data: { memberId },
      } = await Axios.get("/users/init/");

      const id = "goi-" + memberId;
      localStorage.setItem("goi-member-id", id);
      setCookie(cooKeys.goi_member_id, id);

      const user = {
        userId: id,
        properties: getUserProperties(),
      };

      HackleClient.setUser(user);

      customEvent({
        newGtm: {
          action: "signup_or_login_success",
        },
      });
      if (res.status == 201) {
        pixelCustomEvent(PixelEventName.CompleteRegistration);

        const getQuotationStatusAndSendEvent = () => {
          const quotation = getCookie(cooKeys.quotation_request);

          if (quotation) {
            if (quotation.status) {
              const statusEventKey = 장례준비긴급도_LIST_EVENT_KEY[quotation.status];

              customEvent({
                newGtm: {
                  action: `signup_status_${statusEventKey}`,
                },
              });
            }
          }
        };

        getQuotationStatusAndSendEvent();

        customEvent({
          newGtm: {
            action: "signup",
          },

          hackle: { key: HackleEventName.회원가입 },
        });

        if (event_from) {
          customEvent({
            newGtm: {
              path: "login",
              trigger_and_target: `signup_${event_from}`,
            },
          });
        }
      } else {
        customEvent({
          newGtm: {
            action: "login",
          },
          triggerHackle: true,
        });

        if (event_from) {
          customEvent({
            newGtm: {
              path: "login",
              trigger_and_target: `login_${event_from}`,
            },
          });
        }
      }

      if (action) {
        // if (action === "save-estimate") {
        //   postEstimate()
        //     .then((res) => {
        //       if (res.status === 201) {
        //         if (to) {
        //           router.replace(to);
        //         }
        //       }
        //     })
        //     .finally(() => {
        //       removeCookie("estimate-result");
        //       removeCookie(cooKeys.kakao_redirect);
        //     });
        // }
        removeCookie(cooKeys.goi_action);
      } else if (to) {
        if (facilityQuotationRequestId && res.data === "Jangji recommend request already exists.") {
          router.replace(`/match/facility-recommendation/?hasPrevious=true`);
        } else if (
          quotationRequest &&
          quotationRequest.id &&
          res.data === "Saved quotation request user."
        ) {
          router.replace(
            `/match/request-complete?id=${quotationRequest.id}&sido=${quotationRequest.funeral_sido}&sigungu=${quotationRequest.funeral_sigungu}`,
          );
        } else {
          router.replace(to);
        }
        removeCookie(cooKeys.kakao_redirect);
        // removeCookie(cooKeys.quotation_request);
        removeCookie(cooKeys.quotation_request_facility);
        removeCookie(cooKeys.event_from);
      } else {
        router.replace("/");
      }
    }
  };

  const onLoginFailure = (err: any) => {
    if (err.response.data === "Social account with given phone number exists.") {
      openAlreadySignedUp();
      return;
    }

    if (err.response.status === 403) {
      openDormantDrawer();
      return;
    }

    router.replace(router.pathname);
    setIsOpen(true);
  };
  const overlay = useOverlay();
  const overlay2 = useOverlay();
  const handleLogin = ({ isKakao }: { isKakao: boolean }) => {
    const endpoint = `/users/${isKakao ? "kakao" : "easy"}_login/`;
    const body: any = {
      memo: getFromToMemo(),
      quotation_request_id: quotationRequest?.id,
      jangji_recommend_request_id: Number(facilityQuotationRequestId),
    };

    if (isKakao) {
      body["authorization_code"] = router.query.code;
    } else {
      body["telephone"] = 핸드폰번호체크용.value;
    }

    Axios.post(endpoint, body)
      .then(async (res) => onLoginSuccess(res))
      .catch((err) => onLoginFailure(err))
      .finally(() => {
        removeCookie(cooKeys.kakao_auto_login_handled);
      });
  };

  useEffect(() => {
    if (!isLoggedIn && router.query.code) {
      // code확인중에는 페이지 disabled상태로 만들어 놓아야 할 것 같음
      handleLogin({ isKakao: true });
    }
  }, [router.query.code]);

  useEffect(() => {
    const to = router.query.redirectTo;
    if (to) {
      if (!isLoggedIn) {
        setCookie(cooKeys.kakao_redirect, to as string);
      } else router.replace(to as string);
    }
    if (router.query.action) {
      setCookie(cooKeys.goi_action, router.query.action as string);
    }
    if (router.query.event_from) {
      setCookie(cooKeys.event_from, router.query.event_from as string);
    }
  }, [router]);

  const [confirmed, setConfirmed] = useState(false);
  useEffect(() => {
    router.beforePopState(({ as }) => {
      const currentPath = router.asPath;
      if (as !== currentPath) {
        // Will run when leaving the current page; on back/forward actions
        // Add your logic here, like toggling the modal state
        // for example

        if (confirmed) return true;
        else {
          window.history.pushState(null, "", currentPath);
          if (!isLoggedIn) {
            const result = new Promise<boolean>((resolve) => {
              overlay.open(({ isOpen, close: onClose }) => {
                return (
                  <FullscreenDrawer
                    onClose={() => {
                      customEvent({
                        newGtm: {
                          path: "gnb",
                          trigger_and_target: "signup_modal_close",
                        },
                      });
                      onClose();
                      resolve(true);
                    }}
                    isOpen={isOpen}
                    drawerContentStyle={{
                      aspectRatio: 450 / 300,
                      width: "90%",
                      margin: "auto",
                      height: "90%",
                      maxHeight: "600px",
                      maxWidth: "360px",
                    }}
                    body={
                      <FullscreenDrawerContent
                        onClose={() => {
                          onClose();
                          resolve(true);
                        }}
                        onSuccess={() => {
                          onClose();
                          resolve(true);
                        }}
                      />
                    }
                  />
                );
              });
              overlay2.open(({ isOpen, close: onCloseOverlay }) => {
                return (
                  <OverlayModal
                    onClose={onCloseOverlay}
                    isOpen={isOpen}
                    overlayModalContent={
                      <ChatbotModalContent
                        onClose={() => {
                          onCloseOverlay();
                          resolve(true);
                        }}
                      />
                    }
                  />
                );
              });
            });
            result.then((res) => setConfirmed(true));
            return false;
          }
        }
      }
      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
  }, [router, confirmed, isLoggedIn]);

  const handleKakaoAuth = (prompt = "login") => {
    location.href = `https://kauth.kakao.com/oauth/authorize?response_type=code&through_account=true&client_id=778e49a4ec757aa2a95c8e1d48f45e91&prompt=${prompt}&redirect_uri=${location.origin}/login/`;
  };

  const handleNoKakaoLogin = () => {
    if (!핸드폰번호) {
      alert("핸드폰 번호를 입력해주세요.");
    } else if (!핸드폰번호체크용.isValid) {
      alert("입력하신 번호를 확인해주세요.");
    } else if (동의s[0] === false) {
      alert("필수 항목에 동의해주세요.");
    } else {
      customEvent({
        newGtm: {
          path: "login",
          trigger_and_target: "nokakao_start-nokakao_login",
        },
      });

      handleLogin({ isKakao: false });
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    setIsOpen(false);
  };

  if (isKakaoAutoLogin === null || isKakaoAutoLogin) return <></>;

  return (
    <HStack css={backgroundCss()}>
      <VStack css={wrapCss}>
        <Spacing gap={10} />
        {isOpenNoKakao && (
          <Box
            position="absolute"
            background="#00000030"
            css={css`
              display: flex;
              height: 100%;
              width: 100%;
              z-index: 3;
            `}
          >
            <Box
              borderRadius="12px"
              margin={isBiggerThanLg ? "50px auto" : "auto auto"}
              css={css`
                height: fit-content;
                width: 100%;
                max-width: 340px;
                min-width: 312px;
                z-index: 4;
              `}
              background={colors.warmGray50}
            >
              <VStack
                gap={48}
                css={css`
                  padding: 36px 24px 24px;
                `}
              >
                <VStack gap={12}>
                  <HStack
                    className="head2_b"
                    alignItems="start"
                    justifyContent="space-between"
                    css={css`
                      textshadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
                      color: gray900;
                    `}
                  >
                    <div>
                      막막한 장례,
                      <br />
                      한번에 알아보세요.
                    </div>
                    <Box
                      onClick={() => {
                        customEvent({
                          newGtm: {
                            path: "login",
                            trigger_and_target: "nokakao_modal-close",
                          },
                        });
                        onToggleOpenNoKakao();
                      }}
                    >
                      <ColorIcon name="close" color={colors.gray800} size={36} />
                    </Box>
                  </HStack>

                  <Box
                    className="subtitle_14_b"
                    css={css`
                      color: ${colors.gray800};
                    `}
                  >
                    장례식장 빈소 할인, 장지 상담, 맞춤형 견적까지
                    <br />
                    전문 장례지도사가 고객님을 도와드립니다.
                  </Box>
                </VStack>

                <VStack gap={12}>
                  <Box className="subtitle_16_sb gray900">
                    카카오톡 없이 서비스를 계속 이용할 수 있어요
                  </Box>
                  <Field.Input
                    variant="outlined"
                    id="phone-input-no-kakao"
                    value={핸드폰번호}
                    onChange={(v) => {
                      set핸드폰번호(v);
                    }}
                    placeholder="핸드폰 번호를 입력해주세요"
                  />
                  <Box pl="6px" className="caption_11_m gray600">
                    {핸드폰번호체크용.isValid
                      ? `번호 확인: ${핸드폰번호체크용.value}`
                      : "010-0000-0000 형태로 입력해주세요"}
                  </Box>
                </VStack>

                <VStack padding="0 12px">
                  <VStack gap={16}>
                    <Box className="caption_12_b gray700">서비스 이용약관 및 동의 항목</Box>
                    <HStack>
                      <Checkbox
                        index={0}
                        label="전체 동의하기"
                        checked={동의s[0]}
                        onChange={(e) => {
                          toggle동의s(0);
                        }}
                      />
                    </HStack>
                    <HLine color={colors.gray200} width="100%" height="2px" />
                    <VStack gap={8}>
                      <HStack alignItems="center" justifyContent="space-between">
                        <Checkbox
                          index={1}
                          label="[필수] 서비스 이용약관"
                          checked={동의s[1]}
                          onChange={(e) => {
                            toggle동의s(1);
                          }}
                        />
                        <a
                          className="caption_11_b"
                          href="https://goifuneral.notion.site/22e2ed3aa6c54e04822d9669c572c0a1"
                          target="_blank"
                        >
                          보기
                        </a>
                      </HStack>

                      <HStack alignItems="center" justifyContent="space-between">
                        <Checkbox
                          index={2}
                          label="[필수] 개인정보 수집, 이용, 제공 동의"
                          checked={동의s[2]}
                          onChange={(e) => {
                            toggle동의s(2);
                          }}
                        />
                        <a
                          className="caption_11_b"
                          href="https://goifuneral.notion.site/146a79013e6c40a9b4731dd07a8ad99d"
                          target="_blank"
                        >
                          보기
                        </a>
                      </HStack>

                      <HStack alignItems="center" justifyContent="space-between">
                        <Checkbox
                          index={3}
                          label="[필수] 긴급 상황 시 수신 동의(야간)"
                          checked={동의s[3]}
                          onChange={(e) => {
                            toggle동의s(3);
                          }}
                        />
                        <a
                          className="caption_11_b"
                          href="https://goifuneral.notion.site/3e893cfdb8584fb28a4086a60aef5ad4"
                          target="_blank"
                        >
                          보기
                        </a>
                      </HStack>
                    </VStack>
                  </VStack>
                </VStack>
                <Box position="static" width="100%">
                  <LargeButton
                    borderRadius="6px"
                    buttonType={LARGEL_BUTTON_TYPE.ORANGE}
                    className="body_16_m white"
                    onClick={handleNoKakaoLogin}
                  >
                    <HStack width="100%" alignItems="center" justifyContent="center" gap={8}>
                      <div>시작하기</div>
                    </HStack>
                  </LargeButton>
                </Box>
              </VStack>
            </Box>
          </Box>
        )}

        {/* {variation === "B" && <PlainLogin />} */}
        {/* {variation === "B" && (
          <VStack gap={16} css={textContentCss}>
            <VStack gap={12}>
              <HStack padding="10px 0" gap={10}>
                <Button
                  style={{
                    borderRadius: 32,
                    backgroundColor: colors.warmGray400,
                    padding: "8px 16px",
                    maxWidth: "420px",
                    margin: "auto",
                  }}
                  onClick={() => {
                    customEvent({
                      newGtm: {
                        path: "login",
                        trigger_and_target: "call",
                      },
                    });
                    call("1666-9784");
                  }}
                >
                  <HStack alignItems="center" gap={8} justifyContent="space-between">
                    <div className="subtitle_14_b warmGray900">가입 없이 바로 상담하기</div>
                    <ColorIcon
                      name="icon-call-mono"
                      color={colors.warmGray900}
                      width={16}
                      height={16}
                    />
                  </HStack>
                </Button>
              </HStack>
              <Box className="head2_b" css={titleCss}>
                막막한 장례,
                <br />
                한번에 알아보세요.
              </Box>
              <Box className="subtitle_14_b" css={subCss}>
                장례식장 빈소 할인, 장지 상담, 맞춤형 견적까지
                <br />
                전문 장례지도사가 고객님을 도와드립니다.
              </Box>
              <Spacing gap={20} />
            </VStack>
          </VStack>
        )} */}

        <VStack css={buttonCss} gap={12}>
          <KakaoButton
            kakaoButtonLoginText={kakaoButtonLoginText}
            onClick={handleKakaoAuth}
            event={{
              newGtm: {
                path: "login",
                trigger_and_target: "kakao_start-kakao_login",
              },
              hackle: { key: HackleEventName["로그인/회원가입 클릭"] },
            }}
          />
          <Box
            css={css`
              width: 100%;
              max-width: 412px;
              min-width: 312px;
              margin: auto;
              height: 56px;
            `}
          >
            <LargeButton
              borderRadius="6px"
              buttonType={LARGEL_BUTTON_TYPE.GRAY900}
              className="body_16_m white"
              onClick={() => {
                customEvent({
                  newGtm: {
                    path: "login",
                    trigger_and_target: "nokakao-open_nokakao_modal",
                  },
                });
                onToggleOpenNoKakao();
              }}
            >
              <HStack width="100%" alignItems="center" justifyContent="center" gap={8}>
                <div>카카오톡 없이 계속 이용하기</div>
                <ColorIcon name="arrow_right_small" color={colors.white} width={16} height={16} />
              </HStack>
            </LargeButton>
          </Box>
        </VStack>
      </VStack>
      <Dialog
        type={3}
        isOpen={isOpen}
        onClose={onClose}
        header="로그인에 실패했습니다"
        footerProps={{
          text: "확인",
          onClick: handleConfirm,
        }}
      >
        <div css={{ marginTop: "20px" }} />
        다시 시도해 주세요.
        <div css={{ marginTop: "45px" }} />
      </Dialog>
      <DormantDrawer drawerOpen={isOpenDormantDrawer} onDrawerClose={() => closeDormantDrawer()} />

      <Dialog
        type={3}
        isOpen={isOpenAlreadySignedUp}
        onClose={closeAlreadySignedUp}
        header="이미 카카오톡으로 가입하셨습니다."
        footerProps={{
          text: "확인",
          onClick: closeAlreadySignedUp,
        }}
      >
        <div css={{ marginTop: "20px" }} />
        카카오톡 로그인을 이용해 주세요.
        <div css={{ marginTop: "45px" }} />
      </Dialog>
    </HStack>
  );
};

const backgroundCss = () =>
  css({
    position: "relative",
    background: "black",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [MQ.lg]: {
      backgroundPosition: "75% 50%",
    },
  });

const wrapCss = css({
  position: "relative",
  margin: "0 auto",
  width: "100%",
  height: "calc(var(--vh, 1vh) * 100)",
  background: "transprent",
  alignItems: "center",
  [MQ.lg]: {
    margin: "0 0 0 auto",
    width: "360px",
  },
});

// const textContentCss = css({
//   padding: "16px 24px 0px",

//   [MQ.lg]: {
//     padding: "100px 24px 0px",
//   },
// });

// const titleCss = css({
//   textShadow: "0px 0px 12px rgba(0,0,0,0.25)",
//   color: "white",
// });

// const subCss = css({
//   color: colors.gray200,
// });

// const white = css({
//   color: colors.white,
// });

const buttonCss = css({
  position: "absolute",
  bottom: "24px",
  width: "100%",
  padding: "0px 24px",
});

// const PlainLogin = () => {
//   return (
//     <VStack
//       gap={40}
//       css={{
//         color: "white",
//         justifyContent: "center",
//         alignItems: "center",
//         height: "50vh",
//       }}
//     >
//       <GoiLogo color="white" size={56} />
//       장례의 모든 것, 고이장례연구소
//     </VStack>
//   );
// };

// const EndingCredit = () => {
//   const reviews = [
//     {
//       by: "고OO",
//       at: "2024년 3월 28일",
//       content:
//         "헌신적이고 정성스러운 절차 진행에 가족 모두가 \n 감사함을 넘어 미안함을 느낄 정도였습니다.",
//     },
//     {
//       by: "윤OO",
//       at: "2024년 3월 25일",
//       content:
//         "훗날, 어머님의 장례가 팀장님 덕분에\n절망스럽고 고통스러웠을 순간이 아닌\n소중하고 행복한 시간으로 기억될 것입니다.",
//     },
//     {
//       by: "전OO",
//       at: "2024년 3월 25일",
//       content:
//         "합리적인 가격은 물론이고 유가족이 바쁘고 정신없는\n와중에 미처 놓치고 확인할 수 없는\n부분까지 체크하고 미사용금액은 추후에라도\n환급해주시니 더욱 믿음이 갔습니다.",
//     },
//     {
//       by: "차OO",
//       at: "2024년 3월 9일",
//       content:
//         "정말 친절하게 잘 알려주시고 배려해주셔서 오히려 고생하시는것같아 죄송했던게 단점이라고 할 만큼 정말 다시 한번 감사하다고 전달해드리고 싶어요.",
//     },
//     {
//       by: "조OO",
//       at: "2024년 2월 27일",
//       content:
//         "일부의 상조나 장례식장에서 상사를 갑자기 맞이한 이들에게 다시금 슬프게 하는 일이 있다는데, 고이상조는 달랐고 매우 위로가 되었습니다.",
//     },

//     {
//       by: "나OO",
//       at: "2024년 1월 31일",
//       content:
//         "비용이나 절차 모두 상담하신 것에서 한치도 벗어나지 않고, 넘치면 넘쳤지 모자라는 부분 없이 모든 것이 너무 감사합니다.",
//     },
//   ];

//   const RepeatComponent = () => {
//     return (
//       <>
//         <Box className="subtitle_16_b gray500" paddingTop="250px">
//           막막한 장례,
//         </Box>
//         <Box className="subtitle_16_sb gray50" paddingBottom="36px">
//           고이와 함께 해주신 분들
//         </Box>
//         <VStack width="100%" justifyContent="center" alignItems="center" gap={40}>
//           {reviews.map((review) => (
//             <VStack
//               key={review.content}
//               width="100%"
//               gap={8}
//               alignItems="center"
//               justifyContent="center"
//             >
//               <Box
//                 width="100%"
//                 alignItems="center"
//                 textAlign="center"
//                 className="gray500 body_14_m"
//               >
//                 <b>{review.by}</b>
//                 {" | "}
//                 {review.at}
//               </Box>
//               <Box
//                 maxWidth="320px"
//                 className="body_14_r gray100"
//                 whiteSpace="pre-line"
//                 wordBreak="break-all"
//               >
//                 {review.content}
//               </Box>
//             </VStack>
//           ))}
//           <HStack
//             width="100%"
//             alignItems="center"
//             justifyContent="center"
//             gap={12}
//             padding="0 0 300px"
//           >
//             <span className="subtitle_16_sb white">장례의 모든 것,</span>
//             <GoiLogo size={24} color={colors.white} />
//           </HStack>
//         </VStack>
//       </>
//     );
//   };

//   return (
//     <Box position="relative" width="100%" height="calc(100vh - 160px)" overflow="hidden">
//       <VStack
//         width="100%"
//         css={css`
//           position: absolute;
//           height: 400vh;
//           color: #fff;
//           animation: 24s credits linear;
//           animation-delay: 0s;
//           text-align: center;
//           @keyframes credits {
//             0% {
//               top: 0px;
//             }
//             100% {
//               top: -1600px;
//             }
//           }
//         `}
//       >
//         <RepeatComponent />
//       </VStack>
//     </Box>
//   );
// };
