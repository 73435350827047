import { colors, VStack, VLine } from "goi_common";

import { OverlayDrawerHeaderProps } from ".";

export function OverlayDrawerHeaderTitleType({ props }: { props: OverlayDrawerHeaderProps }) {
  const { title } = props.item;

  return (
    <>
      <VStack width="100%" padding="24px 24px 10px">
        <div>{title}</div>
      </VStack>
      <VLine width="100%" height="1px" color={colors.gray100} />
    </>
  );
}
